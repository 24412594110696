@tailwind base;
@tailwind components;

animate-enter {
  animation: enter 0.2s ease-out;
}

animate-leave {
  animation: leave 0.15s ease-in forwards;
}

::selection {
  @apply bg-green-400;
  @apply text-gray-900;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.aibes-logo {
  @apply relative;
  @apply flex;
  @apply border-none;
  @apply rounded;
  @apply overflow-hidden;
  @apply z-10;

  width: 24px;
  height: 24px;
}

.aibes-logo .primary,
.aibes-logo .secondary,
.aibes-logo .tertiary {
  @apply block;
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
}

.aibes-logo .primary {
  @apply bg-gray-900;
  @apply z-20;

  max-width: 0.53333rem;
  max-height: 0.53333rem;
}

.aibes-logo .secondary {
  @apply bg-green-500;
  @apply z-10;

  max-width: 1rem;
  max-height: 1rem;
}

.aibes-logo .tertiary {
  @apply bg-green-400;
  @apply z-0;
}

#__next {
  @apply flex;
  @apply w-full;
}

@tailwind utilities;
